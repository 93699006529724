import React from "react";
import Helmet from "react-helmet";
// import ContactBar from '../components/ContactBar';
import Layout from "../components/Layout";
import Hero from "../components/Hero";

const NotFound = ({ location }) => (
  <Layout location={location}>
    <Helmet>
      <title>404 - Seite nicht gefunden</title>
    </Helmet>
    <div id="pricing" className="page">
      <Hero small />
      <div className="container">
        <div className="content">
          <h1>Seite nicht gefunden</h1>
          <p>Sorry, diese Seite existiert nicht.</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFound;
