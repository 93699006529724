import React from 'react';
import { graphql, StaticQuery } from "gatsby";
import SingleImage from '../library/SingleImage';

class Hero extends React.Component {

  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  componentDidMount () {
    this.timeoutId = setTimeout(function () {
      this.setState({loaded: true});
    }.bind(this), 300);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const clazz = `hero ${this.props.small ? 'small' : ''} ${this.state.loaded ? 'loaded' : ''}`;
    return (
      <SingleImage className={clazz} image={this.props.small ? this.props.data.heroSmall.fluid : this.props.data.heroLarge.fluid} title=<h1><div className="pre">Ihr Spezialist für</div><strong>Bedachungen und Gerüstbau</strong><div className="post">in der Region <strong>Rüti</strong> und <strong>Zürcher Oberland</strong></div></h1> />
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeroImagesQuery {
        heroLarge: imageSharp(fluid: { originalName: {regex: "/front4/" }}) {
          fluid(maxHeight: 350, maxWidth: 800, cropFocus: ENTROPY ) {
            ...GatsbyImageSharpFluid
          }
        },
        heroSmall: imageSharp(fluid: { originalName: {regex: "/front4/" }}) {
          fluid(maxHeight: 150, maxWidth: 800, cropFocus: ENTROPY ) {
            ...GatsbyImageSharpFluid
          }
        },
      }
    `}
    render={data => <Hero data={data} {...props} />}
  />
);
