import React from 'react';
import Img from 'gatsby-image';


export default function SingleImage({ title, caption, image, className, alt }) {
  const imageClass = `image-container ${className ? className : ''}`;
  return (
    <div className={imageClass}>
      <Img sizes={image} alt={alt} title={alt} />
      <div className="text">
        {title ? <div className="title">{title}</div> : null}
        {caption ? <div className="caption">{caption}</div> : null }
      </div>
    </div>
  );
}

SingleImage.propTypes = {

};
